/***** Header *****/

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 87px 0 15px;
  color: #fff;

  z-index: 10;
  transition: all 0.3s;
  @include max($huge) {
    padding: 40px 0 15px;
  }
  @include max($xxl) {
    padding: 15px 0;
  }
  .header-i {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    margin-right: 160px;
    line-height: 0;
    width: 86px;
    height: 68px;
    position: relative;
    transition: all 0.2s;
    @include max($huge) {
      margin-right: 60px;
      height: 40px;
      width: 60px;
    }
    @include max($xxl) {
      margin-right: 40px;
    }
    a {
      display: block;
      line-height: 0;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .header-nav {
    margin-left: auto;
    display: flex;
    gap: 125px;
    @include max($huge) {
      gap: 60px;
    }
    @include max($xxl) {
      gap: 40px;
    }
    @include max($lg) {
      opacity: 0;
      visibility: hidden;
      background: $primary;
      color: $secondary;
      position: fixed;
      top: 70px;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      transition: all 0.3s;
    }
    @include max($lg) {
      // color: #000;
    }
    @include max($sm) {
      top: 70px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 60px;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      @include max($huge) {
        gap: 40px;
        font-size: 16px;
      }
      @include max($xxl) {
        font-size: 14px;
        gap: 30px;
      }
      @include max($lg) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        text-align: center;
        font-size: 20px;
        margin: auto 0;
      }
      li {
        margin: 0;
        letter-spacing: 0.05em;
        > a,
        > span {
          padding: 2px 0;
          color: #fff;
          border-bottom: 2px solid transparent;
          display: block;
          @include max($lg) {
            color: #fff;
          }
        }
        > a {
          &:hover {
            border-bottom: 2px solid $secondary;
          }
        }
        > span {
          cursor: default;
          border-bottom: 2px solid $secondary;
        }
      }
    }

    .social-icons {
      gap: 20px;
      @include max($huge) {
        gap: 15px;
      }
      a {
        .icon {
          width: 20px;
          height: 20px;
          object-fit: contain;
          fill: #fff;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .toggler {
    display: none;
    margin-left: auto;
    width: 36px;
    height: 36px;
    position: relative;
    cursor: pointer;
    @include max($lg) {
      display: block;
    }
    .icon {
      position: absolute;
      overflow: visible;
      margin: auto;
      // width: 100%;
      // height: 100%;
      fill: #fff;
      transition: all 0.1s;
      &.icon-menu {
        width: 36px;
        height: 19px;
        margin-top: 8px;
      }
      &.icon-cross {
        opacity: 0;
        visibility: hidden;
        width: 24px;
        height: 24px;
        margin: 6px;
      }
    }
    &:hover {
      .icon {
        opacity: 0.8;
      }
    }
  }
}

body.is-scrolled {
  .header {
    background: $primary;
    box-shadow: 0 0 10px rgba(#000, 0.1);
    padding: 15px 0;
    .logo {
      height: 40px;
    }
  }
}
body.is-mobile-menu {
  overflow: hidden;
  .header {
    background: $primary;
    .header-nav {
      opacity: 1;
      visibility: visible;
    }
    .toggler {
      .icon {
        &.icon-menu {
          opacity: 0;
          visibility: hidden;
        }
        &.icon-cross {
          opacity: 1;
          visibility: visible;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
