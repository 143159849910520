/***** Simple elements, atoms *****/

.icon {
  fill: currentColor;
  max-height: 100%;
  max-width: 100%;
}

.highlight {
  color: $primary;
}
