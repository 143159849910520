/***** Homepage *****/

.section-home-hero {
  color: #fff;
  .swiper-slide {
    height: 90vh;
    position: relative;
    &:not(.swiper-slide-active) {
      opacity: 0 !important;
    }
    .slide-img {
      position: relative;
      z-index: 0;
      height: 100%;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.35);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .slide-content {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .container {
      .slide-logo {
        margin: 0 0 30px;
        text-align: center;
        line-height: 0;
        display: block;
        @include max($lg) {
          margin-bottom: 20px;
        }
        img {
          max-width: 820px;
          @include max($xxl) {
            max-width: 60%;
          }
          @include max($sm) {
            max-width: 80%;
          }
        }
      }
      .subtitle {
        font-size: 23px;
        letter-spacing: 0.18em;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        @include max($lg) {
          font-size: 20px;
        }
        @include max($sm) {
          font-size: 18px;
        }
      }
    }
  }
  .swiper-pagination {
    line-height: 0;
    bottom: 78px;
    @include max($xxl) {
      bottom: 30px;
    }
    .swiper-pagination-bullet {
      opacity: 0.7;
      background: #fff;
      width: 12px;
      height: 12px;
      margin: 0 3px;
    }
    .swiper-pagination-bullet-active {
      background: #fff;
      opacity: 1;
    }
  }
}

.section-cta {
  background: #fafafa;
  padding: 90px 0 110px;
  @include max($xxl) {
    padding: 60px 0;
  }
  @include max($lg) {
  }
  .container {
    max-width: 1420px;
  }
  .cta-i {
    display: flex;
    @include max($lg) {
      display: block;
    }
    .cta-l {
      flex: 0 0 50%;
      width: 50%;
      padding-right: 30px;
      @include max($lg) {
        width: 100%;
        padding: 0 0 20px;
      }
      .h2 {
        margin: 0;
      }
    }
    .cta-r {
      flex: 1;
      .txt {
        margin: 0 0 30px;
        @include max($lg) {
          margin: 0 0 25px;
        }
      }
      .btn {
        &.btn-primary {
        }
      }
    }
  }
}

.section-info {
  &.section-info--gap {
    @include max($lg) {
      margin-top: 10px;
    }
  }
  .info-block {
    display: flex;
    align-items: stretch;
    @include max($lg) {
      flex-direction: column;
    }
  }
  .info-img {
    width: 50%;
    flex: 0 0 50%;
    line-height: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include max($lg) {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      max-height: 1113px;
      object-fit: cover;
      @include max($lg) {
        max-height: 800px;
      }
    }
  }
  .info-txt {
    width: 50%;
    flex: 0 0 50%;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include max($lg) {
      width: 100%;
      padding: 40px 20px 80px;
    }
    @include max($sm) {
      padding: 40px 20px 60px;
    }
    .info-txt-i {
      max-width: 500px;
      @include min($xxhuge) {
        min-width: 600px;
      }
      @include max($lg) {
        max-width: 100%;
      }
    }
    h2 {
    }
    .info-desc {
      margin-bottom: 40px;
      @include max($xxl) {
        margin-bottom: 25px;
      }
    }
    .info-feature-list {
      margin: -20px 0 60px;
      @include max($lg) {
        margin-bottom: 20px;
      }
      .item {
        display: flex;
        align-items: center;
        padding: 23px 0;
        @include max($lg) {
          padding: 10px 0;
        }
        &:not(:last-child) {
          border-bottom: 1px solid rgba(#76c8d7, 0.3);
        }
        .item-icon {
          width: 65px;
          flex: 0 0 65px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 25px;
          @include max($lg) {
            margin-right: 15px;
          }
          img {
            max-height: 100%;
          }
        }
        .item-title {
          font-weight: 200;
          font-size: 26px;
          @include max($lg) {
            font-size: 20px;
          }
        }
      }
    }
    .btn {
      &.btn-primary {
      }
    }
  }
  .info-block--left {
    .info-img {
      order: 2;
      @include max($lg) {
        order: 1;
      }
    }
    .info-txt {
      order: 1;
      @include max($lg) {
        order: 2;
      }
    }
  }
}

.section-features {
  background: $lgrey;
  padding: 190px 0 220px;
  @include max($xxl) {
    padding: 60px 0;
  }
  .container {
    h2 {
      text-align: center;
      margin-bottom: 80px;
      @include max($xxl) {
        margin-bottom: 40px;
      }
      @include max($lg) {
        margin-bottom: 30px;
      }
    }
    .feature-list {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin: 0 -25px -50px;
      @include max($xxl) {
        margin: 0 -20px -40px;
      }
      .feature-item {
        width: 33.3333%;
        flex: 0 0 33.3333%;
        padding: 0 25px 50px;
        display: flex;
        align-items: stretch;
        @include max($xxl) {
          padding: 0 20px 40px;
        }
        @include max($lg) {
          width: 100%;
          flex: 0 0 100%;
        }
        .card {
          width: 100%;
        }
      }
    }
  }
}

.section-brands {
  padding: 90px 0;
  @include max($xxl) {
    padding: 60px 0;
  }
  @include max($sm) {
    padding: 40px 0;
  }
  .brands-i {
    position: relative;
    @include max($huge) {
      padding: 0 30px;
    }
    @include max($lg) {
      padding: 0 40px;
    }
  }
  .swiper-slide {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    height: auto;
    @include max($xxl) {
      padding: 10px;
    }
    img {
    }
  }
  .swiper-prev,
  .swiper-next {
    width: 36px;
    height: 36px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 18px);
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      opacity: 0.8;
    }
  }
  .swiper-prev {
    left: -80px;
    @include max($huge) {
      left: -20px;
    }
    @include max($lg) {
      left: 0;
    }
  }
  .swiper-next {
    right: -80px;
    @include max($huge) {
      right: -20px;
    }
    @include max($lg) {
      right: 0;
    }
  }
}

.section-gallery {
  .gallery-list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    .gallery-item {
      line-height: 0;
      width: 25%;
      flex: 0 0 25%;
      @include max($lg) {
        width: 50%;
        flex: 0 0 50%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
