/***** Footer *****/

.footer {
  background: $secondary;
  color: #fff;
  .container {
    max-width: 1380px;
  }
}

.footer-t {
  padding: 175px 0 200px;
  @include max($xxl) {
    padding: 80px 0;
  }
  @include max($lg) {
    padding: 60px 0;
  }
  .footer-t-i {
    display: flex;
    flex-wrap: wrap;
  }
  .footer-block {
    &:not(:last-child) {
      margin-bottom: 60px;
    }
    h4 {
      margin-bottom: 30px;
      @include max($xxl) {
        margin-bottom: 20px;
      }
    }
    .footer-block-body {
    }
  }
  .footer-t-row {
    &.footer-t-row--form {
      width: 45%;
      flex: 0 0 45%;
      padding-right: 170px;
      @include max($huge) {
        padding-right: 80px;
      }
      @include max($lg) {
        width: 100%;
        flex: 0 0 100%;
        padding: 0 0 40px;
        max-width: 600px;
      }
      h2 {
        margin-bottom: 25px;
      }
      p {
        line-height: 1.3;
        margin-bottom: 35px;
      }
      form {
      }
      .form-group {
        .form-control {
          background: #fff;
          border: none;
          padding: 10px 20px;
          font-size: 20px;
          font-weight: 200;
        }
      }
      .form-row {
        display: flex;
        @include max($xxs) {
          display: block;
        }
        .form-group {
          flex: 1;
          margin-right: 14px;
          @include max($xxs) {
            margin: 0 0 17px;
          }
          .form-control {
            width: 100%;
          }
        }
        .btn {
          flex: 0 0 auto;
          @include max($xxs) {
            width: 100%;
          }
        }
      }
    }
    &.footer-t-row--middle {
      width: 38%;
      flex: 0 0 38%;
      padding-right: 155px;
      @include max($huge) {
        padding-right: 70px;
      }
      @include max($lg) {
        width: 60%;
        flex: 0 0 60%;
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
        padding: 0 0 40px;
      }
      .social-icons {
        gap: 40px;
        @include max($xxl) {
          gap: 30px;
        }
        a {
          color: $primary;
          &:hover {
            opacity: 0.9;
          }
        }
      }
      .contact-items {
        .contact-item {
          display: flex;
          align-items: flex-start;
          &:not(:last-child) {
            margin-bottom: 25px;
            @include max($xxl) {
              margin-bottom: 20px;
            }
          }
          .item-icon {
            width: 26px;
            flex: 0 0 26px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
            color: $primary;
          }
          a {
            color: #fff;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    &.footer-t-row--right {
      flex: 1;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          font-weight: 500;
          &:not(:last-child) {
            margin-bottom: 27px;
            @include max($xxl) {
              margin-bottom: 20px;
            }
          }
          a {
            color: #fff;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}

.footer-b {
  border-top: 1px solid rgba(#fff, 0.3);
  padding: 45px 0;
  font-weight: 200;
  @include max($xxl) {
    padding: 20px 0;
  }
  .footer-b-i {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .footer-copy {
      margin-right: 20px;
    }
    .footer-by {
      margin-left: auto;
      a {
        color: #fff;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
