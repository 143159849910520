/***** Default and helpers *****/

@import "mixins";
@import "variables";

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-ExtraLight.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html {
  padding: 0;
  margin: 0;
  height: 100%;
}
body {
  overflow-x: hidden;
  color: $black;
  font-family: $font-basic;
  font-size: 20px;
  height: 100%;
  line-height: 1.55;
  @include max($xxl) {
    font-size: 18px;
  }
  @include max($sm) {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

input[type="submit"],
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="url"],
textarea {
  -webkit-appearance: none;
  appearance: none;
}

input,
textarea,
label {
  font-family: $font-basic;
}
a,
button,
input,
textarea,
select {
  outline: none;
}

input,
textarea {
  @include placeholder {
    opacity: 1;
    color: #000;
  }
  &:focus {
    @include placeholder {
      opacity: 0.7;
      color: #000;
    }
  }
}

textarea {
  resize: vertical;
}

table {
  border-collapse: collapse;
}

a {
  color: $black;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: $font-heading;
  font-weight: 200;
  line-height: 1.2;
}

h1,
.h1 {
  margin: 0 0 30px;
  font-size: 94px;
  @include max($xxl) {
    font-size: 58px;
    margin-bottom: 20px;
  }
  @include max($lg) {
    margin-bottom: 15px;
  }
  @include max($sm) {
    font-size: 43px;
    margin-bottom: 10px;
  }
}
h2,
.h2 {
  margin: 0 0 40px;
  font-size: 58px;
  @include max($xxl) {
    font-size: 48px;
    margin-bottom: 20px;
  }
  @include max($sm) {
    font-size: 36px;
  }
}
h3,
.h3 {
  margin: 0 0 30px;
  font-size: 43px;
  @include max($xxl) {
    font-size: 36px;
    margin-bottom: 20px;
  }
  @include max($sm) {
    font-size: 30px;
  }
}
h4,
.h4 {
  font-size: 30px;
  margin: 0 0 20px;
  @include max($xxl) {
    font-size: 27px;
    margin-bottom: 15px;
  }
  @include max($sm) {
    font-size: 25px;
  }
}

p {
  margin: 0 0 1em;
}

b,
strong {
}

img {
  max-width: 100%;
  height: auto;
}
