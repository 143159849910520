/***** Components, common blocks *****/

.social-icons {
  display: flex;
  align-items: center;
  a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card.card--feature {
  padding: 55px 55px 120px;
  display: block;
  background: $primary;
  position: relative;
  color: #fff;
  @include max($xxl) {
    padding: 30px 30px 80px;
  }
  .card-icon {
    margin-bottom: 16px;
    height: 85px;
    width: 140px;
    display: flex;
    align-items: center;
    @include max($lg) {
      margin-bottom: 10px;
      height: 80px;
    }
    img {
      max-height: 100%;
    }
  }
  h3 {
    margin-bottom: 26px;
    @include max($lg) {
      margin-bottom: 20px;
    }
  }
  .card-desc {
  }
  .card-link {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    position: absolute;
    bottom: 60px;
    left: 60px;
    right: 60px;
    @include max($xxl) {
      bottom: 30px;
      left: 30px;
      right: 30px;
    }
  }
  &.card--feature--secondary {
    background: $secondary;
  }
}
a.card.card--feature {
  &:hover {
    background: darken($primary, 5%);
  }
  &.card--feature--secondary {
    &:hover {
      background: darken($secondary, 5%);
    }
  }
}

.card.card--team {
  .card-photo {
    line-height: 0;
    margin-bottom: 55px;
    @include max($xxl) {
      margin-bottom: 20px;
    }
    img {
    }
  }
  .card-name {
    font-size: 46px;
    margin-bottom: 25px;
    font-weight: 200;
    line-height: 1.2;
    @include max($xxl) {
      font-size: 36px;
      margin-bottom: 10px;
    }
    @include max($sm) {
      font-size: 30px;
    }
  }
  .card-position {
    font-weight: 600;
    color: $primary;
    font-size: 24px;
    @include max($xxl) {
      font-size: 20px;
    }
    @include max($lg) {
      font-size: 18px;
    }
  }
}

.card.card--case-study {
  @include minmax($sm, $lg) {
    display: flex;
  }
  .card-img {
    line-height: 0;
    margin-bottom: 45px;
    @include max($xxl) {
      margin-bottom: 20px;
    }
    @include minmax($sm, $lg) {
      width: 300px;
      flex: 0 0 300px;
      margin-right: 30px;
    }
    a {
      display: block;
      line-height: 0;
    }
    img {
      width: 100%;
    }
  }
  .card-body {
    @include minmax($sm, $lg) {
      flex: 1;
    }
  }
  .card-title {
    margin-bottom: 50px;
    font-size: 46px;
    line-height: 1.2;
    font-weight: 200;
    @include max($xxl) {
      font-size: 36px;
      margin-bottom: 20px;
    }
    @include max($sm) {
      font-size: 30px;
    }
    a {
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .card-desc {
    padding-bottom: 60px;
    margin-bottom: 70px;
    border-bottom: 1px solid rgba(#76c8d7, 0.3);
    @include max($xxl) {
      padding-bottom: 30px;
      margin-bottom: 35px;
    }
    @include max($lg) {
      margin-bottom: 20px;
      padding: 0;
      border: none;
    }
  }
  .btn {
  }
}
