/*** Inner pages ***/

.section-hero {
  position: relative;

  .hero-img {
    height: 674px;
    line-height: 0;
    position: relative;
    z-index: 0;
    @include min($xxhuge) {
      height: 35vw;
    }
    @include max($huge) {
      height: 35vw;
    }
    @include max($lg) {
      height: 400px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.5);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .hero-content {
    color: #fff;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 110px;
    @include max($huge) {
      padding-top: 70px;
    }
    @include max($lg) {
      padding-top: 40px;
    }
  }
  h1 {
  }
  .subtitle {
    font-size: 30px;
    @include max($xxl) {
      font-size: 26px;
    }
    @include max($sm) {
      font-size: 21px;
    }
  }
}

/* About */

.info-block--story {
  .info-img {
    @include max($lg) {
      order: 2;
    }
  }
  .info-txt {
    @include max($lg) {
      order: 1;
      padding: 40px 20px 60px;
    }
  }
}

.section-team {
  background: $lgrey;
  padding: 170px 0 180px;
  @include max($xxl) {
    padding: 60px 0;
  }
  h2 {
    text-align: center;
    margin-bottom: 90px;
    @include max($huge) {
      margin-bottom: 60px;
    }
    @include max($xxl) {
      margin-bottom: 40px;
    }
    @include max($lg) {
      margin-bottom: 30px;
    }
  }
  .team-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px -70px;
    @include max($xxl) {
      margin: 0 -20px -40px;
    }
  }
  .team-item {
    width: 33.3333%;
    flex: 0 0 33.3333%;
    padding: 0 25px 70px;
    @include max($xxl) {
      padding: 0 20px 40px;
    }
    @include max($lg) {
      width: 50%;
      flex: 0 0 50%;
    }
    @include max($sm) {
      width: 100%;
      flex: 0 0 100%;
    }
  }
}

/* Case Studies */

.section-case-studies {
  background: $lgrey;
  padding: 200px 0 280px;
  @include max($huge) {
    padding: 100px 0;
  }
  @include max($lg) {
    padding: 60px 0;
  }
  h2 {
    margin-bottom: 70px;
    @include max($xxl) {
      margin-bottom: 40px;
    }
    @include max($lg) {
      margin-bottom: 30px;
    }
    br {
      @include max($sm) {
        display: none;
      }
    }
  }
  .case-studies-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px -70px;
    @include max($xxl) {
      margin: 0 -20px -70px;
    }
    .case-studies-item {
      width: 33.3333%;
      flex: 0 0 33.3333%;
      padding: 0 25px 70px;
      @include max($xxl) {
        padding: 0 20px 70px;
      }
      @include max($lg) {
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

.section-case-study {
  padding: 130px 0 140px;
  @include max($xxl) {
    padding: 60px 0;
  }
  h2 {
    margin-bottom: 50px;
    @include max($xxl) {
      margin-bottom: 30px;
    }
  }
  .desc {
    margin-bottom: 140px;
    @include max($xxl) {
      margin-bottom: 60px;
    }
  }

  .case-studies-slider {
    position: relative;
    margin-bottom: 135px;
    @include max($xxl) {
      margin-bottom: 60px;
    }
    @include max($lg) {
      margin-bottom: 40px;
    }
    .swiper-slide {
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      height: auto;
      img {
      }
    }
    .swiper-prev,
    .swiper-next {
      width: 36px;
      height: 36px;
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: calc(50% - 18px);
      cursor: pointer;
      transition: all 0.2s;
      z-index: 10;
      &:hover {
        opacity: 0.8;
      }
    }
    .swiper-prev {
      left: -80px;
      @include max($huge) {
        left: 0;
      }
      @include max($lg) {
        left: 0;
      }
    }
    .swiper-next {
      right: -80px;
      @include max($huge) {
        right: -20px;
      }
      @include max($lg) {
        right: 0;
      }
    }
  }
}

/* Contact */

.section-contacts {
  background: $lgrey;
  padding: 100px 0;
  @include max($xxl) {
    padding: 60px 0;
  }
  .contacts-i {
    display: flex;
    align-items: center;
    @include max($lg) {
      display: block;
    }
  }
  .contacts-l {
    width: 50%;
    flex: 0 0 50%;
    padding-right: 30px;
    @include max($lg) {
      width: 100%;
      padding: 0 0 40px;
    }
    h2 {
      margin: 0;
    }
  }
  .contacts-r {
    flex: 1;
    padding: 0 0 0 55px;
    @include max($lg) {
      padding: 0;
    }
  }
  .contact-items {
    .contact-item {
      display: flex;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: 25px;
        @include max($xxl) {
          margin-bottom: 20px;
        }
      }
      .item-icon {
        width: 26px;
        flex: 0 0 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        color: $primary;
      }
      a {
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.section-contact-form {
  display: flex;
  align-items: stretch;
  @include max($lg) {
    display: block;
  }
  .contact-form-img {
    width: 50%;
    flex: 0 0 50%;
    line-height: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include max($lg) {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      max-height: 1113px;
      object-fit: cover;
      @include max($lg) {
        max-height: 800px;
      }
    }
  }
  .contact-form-body {
    width: 50%;
    flex: 0 0 50%;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include max($lg) {
      width: 100%;
      padding: 40px 20px 80px;
    }
    @include max($sm) {
      padding: 40px 20px 60px;
    }
  }
  .contact-form-i {
    max-width: 500px;
    @include min($xxhuge) {
      min-width: 600px;
    }
    @include max($lg) {
      max-width: 100%;
    }
  }
  h2 {
    margin-bottom: 20px;
  }
  p {
  }

  .contact-form {
    .form-group {
      border-bottom: 1px solid rgba(#76c8d7, 0.5);
      margin-bottom: 11px;
      padding-bottom: 11px;
    }
    .form-control {
      border: none;
    }
    textarea {
      resize: none;
      height: 130px;
    }
    .btn {
      margin-top: 50px;
    }
  }
}
