/***** Layout *****/

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1480px;
  @include max($lg) {
    padding: 0 20px;
  }
}
