/* Color */
$primary: #76c8d7;
$secondary: #b42260;
$black: #000;
$lgrey: #fafafa;

$font-heading: "Poppins", sans-serif;
$font-basic: "Poppins", sans-serif;

/* Size */
$xxxs: 375px;
$xxs: 414px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1280px;
$xxxl: 1400px;
$huge: 1600px;
$xhuge: 1800px;
$xxhuge: 2000px;
