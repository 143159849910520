/***** Buttons *****/

.btn {
  cursor: pointer;
  transition: all 0.2s;
  display: inline-block;
  text-align: center;
  padding: 10px 30px;
  border: 2px solid transparent;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: 600;
  height: 52px;
  min-width: 150px;
  &:hover {
  }
  &.btn-primary {
    border-color: $primary;
    background: transparent;
    color: $primary;
    &:hover {
      background: $primary;
      color: #fff;
    }
  }
  &.btn-secondary {
    &:hover {
    }
  }
}
.btns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -4px;
  .btn {
    margin: 0 8px 4px 0;
    &:last-child {
      margin-right: 0;
    }
  }
  &.btns-center {
    justify-content: center;
  }
}
